import '../css/main.scss';
import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';
import '../../node_modules/@glidejs/glide/dist/css/glide.theme.min.css';
import '../../node_modules/vanilla-cookieconsent/dist/cookieconsent.css';
import { run as CookieConsentRun, acceptedCategory } from 'vanilla-cookieconsent';
import Glide from '@glidejs/glide';
import logo from '../images/logo-horizontal.webp';
import logoBranco from '../images/logo-branco.webp';
import menuToggler from '../images/menu.svg';
import bannerBig from '../images/banner-big.webp';
import bannerMobile from '../images/banner-mobile.webp';
import quemsomosMobile from '../images/quemsomos-mobile.webp';
import quemsomosDesktop from '../images/quemsomos.webp';
import salariosMobile from '../images/salarios-mobile.webp';
import salariosDesktop from '../images/salarios.webp';
import contactoMobile from '../images/bg-form-contacto-mobile.webp';
import contactoDesktop from '../images/bg-form-contacto.webp';
//import linkBtn from '../images/link.svg';
import appleIcon57 from '../images/favicon/apple-icon-57x57.png';
import appleIcon60 from '../images/favicon/apple-icon-60x60.png';
import appleIcon72 from '../images/favicon/apple-icon-72x72.png';
import appleIcon76 from '../images/favicon/apple-icon-76x76.png';
import appleIcon114 from '../images/favicon/apple-icon-114x114.png';
import appleIcon120 from '../images/favicon/apple-icon-120x120.png';
import appleIcon144 from '../images/favicon/apple-icon-144x144.png';
import appleIcon152 from '../images/favicon/apple-icon-152x152.png';
import appleIcon180 from '../images/favicon/apple-icon-180x180.png';
import androidIcon192 from '../images/favicon/android-icon-192x192.png';
import favIcon from '../images/favicon/favicon.ico';
import favIcon16 from '../images/favicon/favicon-16x16.png';
import favIcon32 from '../images/favicon/favicon-32x32.png';
import favIcon96 from '../images/favicon/favicon-96x96.png';
import msIcon144 from '../images/favicon/ms-icon-144x144.png';
import ogImage from '../images/ogimage.webp';
const ogimage = document.getElementById('ogimage');
if (ogimage) {
    ogimage.content = 'https://cambiconta.pt/' + ogImage;
}
const generalicon = document.getElementById('generalicon');
if (generalicon) {
    generalicon.href = favIcon;
}
const appleicon57 = document.getElementById('appleicon57');
if (appleicon57) {
    appleicon57.src = appleIcon57;
}
const appleicon60 = document.getElementById('appleicon60');
if (appleicon60) {
    appleicon60.src = appleIcon60;
}
const appleicon72 = document.getElementById('appleicon72');
if (appleicon72) {
    appleicon72.src = appleIcon72;
}
const appleicon76 = document.getElementById('appleicon76');
if (appleicon76) {
    appleicon76.src = appleIcon76;
}
const appleicon114 = document.getElementById('appleicon114');
if (appleicon114) {
    appleicon114.src = appleIcon114;
}
const appleicon120 = document.getElementById('appleicon120');
if (appleicon120) {
    appleicon120.src = appleIcon120;
}
const appleicon144 = document.getElementById('appleicon144');
if (appleicon144) {
    appleicon144.src = appleIcon144;
}
const appleicon152 = document.getElementById('appleicon152');
if (appleicon152) {
    appleicon152.src = appleIcon152;
}
const appleicon180 = document.getElementById('appleicon180');
if (appleicon180) {
    appleicon180.src = appleIcon180;
}
const androidicon192 = document.getElementById('androidicon192');
if (androidicon192) {
    androidicon192.src = androidIcon192;
}
const favicon16 = document.getElementById('favicon16');
if (favicon16) {
    favicon16.src = favIcon16;
}
const favicon32 = document.getElementById('favicon32');
if (favicon32) {
    favicon32.src = favIcon32;
}
const favicon96 = document.getElementById('favicon96');
if (favicon96) {
    favicon96.src = favIcon96;
}
const msicon144 = document.getElementById('msicon144');
if (msicon144) {
    msicon144.src = msIcon144;
}
const logoElement = document.getElementById('logo');
if (logoElement) {
    logoElement.src = logo;
}
const quemsomosmobile = document.getElementById('quemsomosmobile');
if (quemsomosmobile) {
    quemsomosmobile.src = quemsomosMobile;
}
const quemsomosdesktop = document.getElementById('quemsomosdesktop');
if (quemsomosdesktop) {
    quemsomosdesktop.src = quemsomosDesktop;
}
const salariosmobile = document.getElementById('salariosmobile');
if (salariosmobile) {
    salariosmobile.src = salariosMobile;
}
const salariosdesktop = document.getElementById('salariosdesktop');
if (salariosdesktop) {
    salariosdesktop.src = salariosDesktop;
}
const logofooter = document.getElementById('logofooter');
if (logofooter) {
    logofooter.src = logoBranco;
}
// Footer date
const footerdate = document.getElementById('footerdate');
const actualyear = new Date().getFullYear().toString();
if (footerdate) {
    footerdate.innerHTML = actualyear;
}
const logoinmenu = document.getElementById('logoinmenu');
if (logoinmenu) {
    logoinmenu.src = logoBranco;
}
const imgmenutoggler = document.getElementById('imgmenutoggler');
if (imgmenutoggler) {
    imgmenutoggler.src = menuToggler;
}
//Toggle menu
const btnmenu = document.getElementById('menu-toggler');
const btnmenuclose = document.getElementById('menu-toggler-close');
const menu = document.getElementById('menu');
const bodyelement = document.body;
btnmenu.addEventListener('click', function (evt) {
    menu.classList.toggle('opened');
    bodyelement.classList.toggle('menuopened');
});
btnmenuclose.addEventListener('click', function (evt) {
    menu.classList.toggle('opened');
    bodyelement.classList.toggle('menuopened');
});
//Background image from contacts section
const contactssection = document.getElementById('contacts');
const secondcol = document.getElementById('secondcol');
function updateBackgroundImage() {
    if (window.innerWidth <= 768) {
        contactssection.style.backgroundImage = `url(${contactoMobile})`;
        secondcol.style.backgroundImage = `url(${bannerMobile})`;
    }
    else {
        contactssection.style.backgroundImage = `url(${contactoDesktop})`;
        secondcol.style.backgroundImage = `url(${bannerBig})`;
    }
}
updateBackgroundImage();
window.addEventListener('resize', updateBackgroundImage);
//Verify if input is filled
var fname = document.getElementById("fname");
var ftel = document.getElementById("ftel");
var femail = document.getElementById("femail");
var ftext = document.getElementById("ftext");
fname.addEventListener('input', function (evt) {
    if (fname.value) {
        fname.classList.add('filled');
    }
    else {
        fname.classList.remove('filled');
    }
});
ftel.addEventListener('input', function (evt) {
    if (ftel.value) {
        ftel.classList.add('filled');
    }
    else {
        ftel.classList.remove('filled');
    }
});
femail.addEventListener('input', function (evt) {
    if (femail.value) {
        femail.classList.add('filled');
    }
    else {
        femail.classList.remove('filled');
    }
});
ftext.addEventListener('input', function (evt) {
    if (ftext.value) {
        ftext.classList.add('filled');
    }
    else {
        ftext.classList.remove('filled');
    }
});
//Glide carousel --> https://glidejs.com/docs/setup/
const glide = new Glide('.glide', {
    type: 'carousel',
    startAt: 0,
    gap: 40,
    perView: 3,
    animationTimingFunc: 'ease-in-out',
    autoplay: false,
    breakpoints: {
        560: {
            perView: 1
        },
    }
});
/*
glide.on('mount.after', () => {
        console.log('Glide mounted');
});
  */
glide.mount();
//Cookie consent
function loadGoogleAnalytics() {
    console.log('Loading Google Analytics...');
    // Verifica se o script já existe para evitar duplicação
    if (!document.getElementById('ga-script')) {
        const script = document.createElement('script');
        script.id = 'ga-script';
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-Z4818XKB33';
        document.head.appendChild(script);
        const inlineScript = document.createElement('script');
        inlineScript.id = 'ga-inline-script';
        inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-Z4818XKB33');
        `;
        document.head.appendChild(inlineScript);
    }
}
// Function to disable Google Analytics
function disableGoogleAnalytics() {
    console.log('Disabling Google Analytics...');
    window['ga-disable-G-Z4818XKB33'] = true;
    // Remove os scripts do GA
    const gaScript = document.getElementById('ga-script');
    const gaInlineScript = document.getElementById('ga-inline-script');
    if (gaScript)
        gaScript.remove();
    if (gaInlineScript)
        gaInlineScript.remove();
}
// Sempre carrega o GA inicialmente
loadGoogleAnalytics();
const cookieConsent = CookieConsentRun({
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        analytics: {}
    },
    language: {
        default: "en",
        autoDetect: "browser",
        translations: {
            en: {
                consentModal: {
                    title: "Olá visitante, este site usa cookies",
                    description: "A sua privacidade é importante para nós. A CambiConta está empenhada em proteger a privacidade dos seus clientes e, em especial, dos utilizadores do seu website e assume o compromisso de respeitar as regras de proteção de dados.",
                    acceptAllBtn: "Aceitar todos",
                    acceptNecessaryBtn: "Rejeitar todos",
                    showPreferencesBtn: "Gerir preferências",
                    footer: "<a href=\"politicaprivacidade.html\">Política de privacidade</a>\n<a href=\"politicacookies.html\">Política de cookies</a>"
                },
                preferencesModal: {
                    title: "Centro de gestão de prefferências",
                    acceptAllBtn: "Aceitar todos",
                    acceptNecessaryBtn: "Rejeitar todos",
                    savePreferencesBtn: "Gravar preferências",
                    closeIconLabel: "Fechar",
                    serviceCounterLabel: "Serviços",
                    sections: [
                        {
                            title: "Utilização de cookies",
                            description: "Os cookies permitem apenas ao utilizador obter uma navegação mais rápida, eficiente e personalizada ao website, eliminando a necessidade de introduzir repetidamente as mesmas informações."
                        },
                        {
                            title: "Estritamente necessários <span class=\"pm__badge\">Sempre ligados</span>",
                            description: "são cookies essenciais porque garantem a navegação no site e a utilização das respetivas funcionalidades, entre estas, a de registar se o utilizador autorizou ou não a utilização de cookies pela página web. Estas cookies não podem ser rejeitadas ao utilizar um site.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytics Cookies",
                            description: "A informação fornecida pelos cookies analíticos permite-nos analisar padrões do comportamento do visitante, e essas informações são utilizadas para melhorar a experiência em geral ou identificar áreas do website que necessitam de manutenção.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "Mais informação",
                            description: "Para qualquer questão relacionada com gestão de cookies, por favor <a class=\"cc__link\" href=\"contactos.html\">contacte-nos</a>."
                        }
                    ]
                }
            }
        }
    }
});
// Listener para mudanças no consentimento
document.addEventListener('cc:consent', (event) => {
    const consent = event.detail;
    if (consent.categories.includes('analytics')) {
        loadGoogleAnalytics();
    }
    else {
        disableGoogleAnalytics();
    }
});
// Verifica o estado do consentimento ao carregar a página
window.addEventListener('load', () => {
    // Verifica se já existe uma escolha prévia do usuário
    const cookieExists = document.cookie.split(';').some(cookie => cookie.trim().startsWith('cc_cookie='));
    if (cookieExists) {
        // Se já existe uma escolha prévia, respeita essa escolha
        if (!acceptedCategory('analytics')) {
            disableGoogleAnalytics();
        }
        else {
            loadGoogleAnalytics();
        }
    }
    // Se não existe escolha prévia (primeira visita), não faz nada
    // mantendo o GA carregado inicialmente
});
